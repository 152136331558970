<template>
  <div class="flex flex-col h-screen">
    <base-header />
    <slot name="base-body"></slot>
    <base-footer />
  </div>
</template>

<script>
  import { defineComponent } from "vue";
  import BaseHeader from "@/components/base/BaseHeader.vue";
  import BaseFooter from "@/components/base/BaseFooter.vue";

  export default defineComponent({
    name: "BaseLayoutMain",
    components: {
      BaseHeader,
      BaseFooter,
    },
  });
</script>
