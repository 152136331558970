import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export function auth() {
  const router = useRouter()
  const store = useStore()
  const username = ref('')
  const password = ref('')
  const loggedIn = computed(() => store.state.Auth.loggedIn)

  function signIn() {
    try {
      if (username.value == process.env.VUE_APP_USERNAME && password.value == process.env.VUE_APP_PASSWORD)
      store.commit('auth/updateLogin', { loggedIn: true, username: username.value })
    } catch (err) {
      console.log('Sign in failure: ', err)
    }
  }

  function signOut() {
    try {
      store.commit('auth/updateLogin', { loggedIn: false, username: '' })
      router.push({ name: 'Auth' })
    } catch (err) {
      console.log('Signing out: ', err)
    }
  }

  return {
    username,
    password,
    loggedIn,
    signIn,
    signOut
  }
}
