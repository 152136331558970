<template>
  <footer class="p-4">
    <div class="flex flex-col lg:flex-row items-center">
      <div class="w-full lg:w-auto lg:mr-auto text-center lg:text-left">
        &copy; 2021 Toasty. All rights reserved. Various trademarks held by
        their respective owners.
      </div>
      <div class="flex justify-center items-center space-x-6 mt-4 lg:mt-0">
        <a href="#">
          <svg
            class="text-gray-700 w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewbox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
            ></path>
          </svg>
        </a>
        <a href="#">
          <svg
            class="text-gray-700 w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewbox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
            ></path>
          </svg>
        </a>
        <a href="#">
          <svg
            class="text-gray-700 w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewbox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path
              d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "BaseFooter",
  });
</script>

<style>
  footer {
    font-family: "Poppins", sans-serif;
  }
</style>