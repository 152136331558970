<template>
  <base-layout-main>
    <template v-slot:base-body>
      <div>
        <amplify-authenticator username-alias="email">

        <amplify-sign-up
          slot="sign-up"
          username-alias="email"
          :formFields="formFields"
        ></amplify-sign-up>

        <amplify-sign-in
          slot="sign-in"
          username-alias="email"
          federated
        ></amplify-sign-in>

          <div v-if="authState === 'signedin' && user">
            <base-navbar />
            <div>Hello, {{ user.signInUserSession.idToken.payload.name }}</div>
            <base-footer />
          </div>

          <amplify-sign-out></amplify-sign-out>
          
        </amplify-authenticator>
      </div>
    </template>
  </base-layout-main>
</template>

<script>
  import { defineComponent } from 'vue'
  import BaseLayoutMain from '@/layouts/BaseLayoutMain.vue'
  import { onAuthUIStateChange } from '@aws-amplify/ui-components'

  export default defineComponent({
    name: 'Protected',
    components: {
      BaseLayoutMain
    },
    data: () => ({
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      formFields: [
        {
          type: 'name',
          label: 'Name',
          required: true,
        },
        {
          type: 'email',
          label: 'Email',
          required: true,
        },
        {
          type: 'password',
          label: 'Password',
          required: true,
        },
      ]
    }),
    created() {
      this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
        this.authState = authState;
        this.user = authData;
      })
    },
    beforeUnmount() {
      this.unsubscribeAuth();
    }
  })
</script>

<style>
  :root {
    --amplify-font-family: 'Poppins', sans-serif;
    --amplify-primary-color: #5b21b6;
    --amplify-primary-tint: #673095;
    --amplify-primary-shade: #aa49c7;
  }
</style>
