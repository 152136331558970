<template>
  <nav class="flex flex-wrap items-center justify-between p-4">
    <div class="lg:order-2 w-auto lg:w-1/5 lg:text-center">
      <router-link
        class="text-xl text-indigo-600 font-semibold font-heading"
        to="/"
        ><img src="@/assets/logo.png" alt="logo" width="128" class="mx-auto"
      /></router-link>
    </div>
    <div class="block lg:hidden">
      <button
        class="
          navbar-burger
          flex
          items-center
          py-2
          px-3
          text-indigo-500
          rounded
          border border-indigo-500
        "
        @click="showMobileMenu = !showMobileMenu"
      >
        <svg
          class="fill-current h-3 w-3"
          viewbox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </button>
    </div>
    <div
      :class="!showMobileMenu ? 'hidden' : ''"
      class="navbar-menu lg:order-1 lg:block w-full lg:w-2/5"
    >
      <router-link
        v-for="link in navLinks"
        :key="link.index"
        active-class="text-indigo-700"
        class="
          block
          lg:inline-block
          mt-4
          lg:mt-0
          mr-10
          text-gray-700
          hover:text-indigo-700
        "
        :to="link.path"
        >{{ link.name }}</router-link
      >
    </div>
    <div
      :class="!showMobileMenu ? 'hidden' : ''"
      class="navbar-menu lg:order-3 lg:block w-full lg:w-2/5 lg:text-right"
    >
      <a
        active-class="text-indigo-700"
        class="
          block
          lg:inline-block
          mt-4
          lg:mt-0
          mr-10
          text-gray-700
          hover:text-indigo-700
        "
        href="#"
        >FAQ</a
      ><a
        active-class="text-indigo-700"
        class="
          block
          lg:inline-block
          mt-4
          lg:mt-0
          mr-10
          text-gray-700
          hover:text-indigo-700
        "
        >Contact</a
      >
    </div>
  </nav>
</template>

<script>
  import { defineComponent } from "vue";
  import { auth } from "@/helpers/auth";

  export default defineComponent({
    name: "BaseHeader",
    setup() {
      const { loggedIn, signOut } = auth();
      return { loggedIn, signOut };
    },
    data: () => ({
      showMobileMenu: false,
      navLinks: [
        { name: "Home", path: "/" },
        { name: "Apps", path: "/apps" },
      ],
      isMenuOpen: false,
    }),
  });
</script>

<style>
  nav {
    font-family: "Poppins", sans-serif;
  }
</style>